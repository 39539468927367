
























































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import SignableDocumentsDTO from "@/dto/signature/SignableDocumentsDTO";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import SignatureService from "@/services/SignatureService";
import SignableDocumentsFilter from "@/dto/signature/SignableDocumentsFilter";
import {processError} from "@/utils/ComponentUtils";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import {DocumentSigningStatus} from "@/constants/SignatureConstants";
import SelectOption from "@/components/common/SelectOption";
import PortalSelect from "@/components/common/PortalSelect.vue";
import DocumentUtils from "@/utils/DocumentUtils";
import Notifications from "@/state/Notifications";
import SignersDTO from "@/dto/signature/SignersDTO";
import SublimeSearch from "@/components/common/SublimeSearch.vue";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import PortalDate from "@/components/common/PortalDate.vue";
import SelectProfileTypeForPrefilledDocumentModal
  from "@/components/documents/signing/SelectProfileTypeForPrefilledDocumentModal.vue";
import SimpleProfileSearch from "@/components/common/SimpleProfileSearch.vue";
import ProfileDTO from "@/dto/profile/ProfileDTO";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {debounce} from "typescript-debounce-decorator";

const AppModule = namespace("App");

@Component<SignableDocumentsListNew>({
  components: {
    PortalInput,
    SimpleProfileSearch,
    PortalDate,
    SublimeSearch,
    PortalSelect,
    PersonSearch,
    PaginationComponent,
    DeleteConfirmation
  },
  computed: {
    SelectOption() {
      return SelectOption
    },
    DocumentSigningStatus() {
      return DocumentSigningStatus;
    },
    DocumentUtils() {
      return DocumentUtils;
    }
  }
})
export default class SignableDocumentsListNew extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private signer: ProfileDTO | null = null;
  private signableDocuments: Array<SignableDocumentsDTO> | [] = [];
  private filter = new SignableDocumentsFilter();
  private totalPages = 1;
  private isCancelDocumentModalShown = false;
  private documentIdToDelete: number | null = null;
  private activeDropdownId: number | null = null;

  selectedEmployee: SublimeEmployeeDTO | null = null;

  mounted(): void {
    this.loadDocuments();
    document.addEventListener('click', this.handleOutsideClick);
  }

  beforeDestroy(): void {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick(event: MouseEvent): void {

    const target = event.target as HTMLElement;
    if (!target.closest('.signable-doc-dropdown-container')) {
      this.activeDropdownId = null;
    }
  }

  toggleDropdown(docId: number): void {
    this.$nextTick(() => {
      if (this.activeDropdownId === docId) {
        this.activeDropdownId = null;
      } else {
        this.activeDropdownId = docId;
      }
    });
  }

  @debounce(500)
  loadDocuments(): void {
    this.startLoading();
    SignatureService.getSignableDocuments(this.filter).then(
        response => {
          response.data.content.forEach(doc => {
            doc.signers = doc.signers.sort((a, b) => a.order - b.order);
          })
          this.signableDocuments = response.data.content;
          this.totalPages = response.data.totalPages;
          this.stopLoading();
        },
        error => {
          processError(error, this);
          this.stopLoading();
        }
    );
  }

  private selectSigner(signer: PersonDTO) {
    this.filter.signerId = signer.id;
    this.filter.signerType = signer.type;
    this.loadDocuments();
  }

  changePage(page: number): void {
    this.filter.pageNumber = page;
    this.loadDocuments();
  }

  clearFilter(): void {
    this.filter = new SignableDocumentsFilter();
    this.loadDocuments();
  }

  goToCustom(): void {
    this.$router.push({name: this.$routeNames.ARCHIVE_SIGNABLE_CUSTOM, params: this.$route.params})
  }

  sendPrefilled(): void {
    this.$modal.show(
        SelectProfileTypeForPrefilledDocumentModal,
        {
          onCreated: () => {
            this.clearFilter();
            Notifications.info('Document has been sent');
          }
        }
    )
  }

  sendReminder(signer: SignersDTO): void {
    this.startLoading();
    SignatureService.sendReminder(signer.id).then(
        ok => {
          Notifications.info(`Reminder for ${signer.name} has been sent`);
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  onEmployeeChanged(employee: SublimeEmployeeDTO): void {
    this.filter.assigneeId = employee.id;
    this.loadDocuments();
  }

  triggerCallback(documentId: number): void {
    this.startLoading();
    SignatureService.triggerCallback(documentId).then(
        ok => {
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  toggleCancelDocumentModal(documentId?: number): void {
    this.$modal.show(
        DeleteConfirmation,
        {
          onDelete: () => this.cancelDocument(),
          targetName: "",
          operationName: "cancel document",
          cancelButtonName: "Close"
        }
    )

    if (documentId) this.documentIdToDelete = documentId;
    else this.documentIdToDelete = null;

    this.isCancelDocumentModalShown = !this.isCancelDocumentModalShown;
  }

  cancelDocument(): void {
    this.startLoading();
    SignatureService.cancelDocument(this.documentIdToDelete as number).then(
        ok => {
          this.stopLoading();
          this.loadDocuments();
          Notifications.info("Document has been canceled");
        },
        err => processError(err, this)
    );

  }

}
